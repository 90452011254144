@media only screen and (max-width: 768px) {
  .hero-text h1 {
    font-size: 3rem;
  }

  .hero-text p {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .hero-text h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .hero-text p {
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  .header {
    top: 0 !important;
    border-bottom: #eee 1px solid;
    padding: 15px 20px !important;
  }

  main {
    margin-top: 65.41px;
  }

  .hero,
  .hero-text {
    height: calc(100vh - 65.41px);
  }

  section {
    padding: 2rem 1rem !important;
  }

  .mobile-nav {
    right: 0;
    top: 62px;
    z-index: 999;
    border-top: 1px solid #eee;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    height: calc(100vh - 62px);
  }

  .mobile-nav a {
    padding: 10px 20px;
    font-size: 18px;
  }

  .mobile-nav .active {
    font-weight: 600;
  }

  .mobile-nav-show {
    transform: translateX(0);
  }

  .project-image {
    height: 200px !important;
  }

  .project-card .content {
    opacity: 1;
    visibility: visible;
  }

  .logo {
    width: 3rem;
  }

  .page-hero,
  .page-hero .hero-text {
    height: 250px
  }

  .blog {
    padding-bottom: 3rem !important;
  }

  .about {
    padding: 60px 1rem !important;
  }

  .seperator {
    background: #eee;
    height: 1px;
    margin: auto auto 1rem;
  }
}