body {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  color: #18181b !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
  transition: 0.3s ease-in-out !important;
}

img {
  -webkit-user-drag: none;
}

input,
textarea {
  box-shadow: none !important;
  outline: none !important;
  border-color: #dee2e6 !important;
}

.brand-accent-color {
  color: #008afc !important;
}

.brand-accent-bg {
  background-color: #008afc !important;
}

.brand-accent-border {
  border-color: #008afc !important;
}

.custom-btn {
  padding: 10px 35px !important;
}

.btn.brand-accent-bg {
  color: #fff !important;
}

.go-home-btn,
.go-home-btn:hover,
.contact-submit,
.contact-submit:hover {
  background-color: #18181a;
  padding: 8px 20px;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  font-size: 14px;
  width: max-content;
}

.header {
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  transition: 0.3s ease-in-out;
  padding: 0;
  top: 50px;
  box-shadow: none;
}

.header.scrolled {
  padding: 20px;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px, rgb(27 31 35 / 5%) 0px 0px 0px 1px;
}

.navbar ul {
  gap: 2rem;
}

.navbar .active,
.navbar li a:hover {
  color: #008afc !important;
}

.mobile-nav-toggle {
  color: #18181b;
  font-size: 32px;
  cursor: pointer;
  line-height: 0;
  transform: rotate(0);
  transition: 0.3s ease-in-out;
}

.mobile-nav {
  display: none;
}

.hero,
.hero-text {
  height: calc(100vh - 200px);
}

.hero-text h1 {
  margin-bottom: 18px;
  font-weight: 750;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 2.8rem;
}

.hero-text p {
  width: 100%;
  font-size: 18px;
  color: #57534e;
}

.hero .arrow-animation {
  animation: arrow-keyframes 1000ms linear infinite;
}

@keyframes arrow-keyframes {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }
}

.section-bg {
  background: #18181a;
  color: #fff;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  color: #18181b;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 1.25rem;
}

.section-title p {
  color: #939393;
}

.section-bg .section-title h2 {
  font-size: 32px;
  color: #fff;
}

.blog,
.contact {
  padding: 4rem 0;
}

main {
  margin-top: 80px;
}

.blog-card .blog-info,
.work-card .work-type {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1em;
}

.blog-card .blog-info span:first-child::after {
  content: '•';
  padding: 0 8px;
}

.blog-card h3,
.work-card h3 {
  font-size: 22px;
}

.blog-image,
.work-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.blog-image {
  background-position: top;
  height: 250px;
}

.work-image {
  background-position: center center;
  height: 295px;
}

.blog-card .content,
.work-card .content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  border: #eee 1px solid;
}

.blog-card .content i,
.work-card .content i {
  font-size: 22px;
}

footer ul li i {
  padding-right: 10px;
  font-size: 18px;
}

.work h5 {
  letter-spacing: 0.01em;
  font-size: 0.75rem;
  line-height: 1em;
  background-color: #eef0f3;
  border-radius: 1.5rem;
  height: 1.75rem;
  padding: 0 0.875rem;
  white-space: nowrap;
}

.about {
  padding: 60px 0;
}

.error-page-hero {
  height: calc(100vh - 155px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-page-hero h1 {
  font-size: 6.5rem;
}

.page-hero,
.page-hero .hero-text {
  height: 250px;
}